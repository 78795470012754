
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CartDetails from './CartDetails.vue';
@Options({
    components: {
        FontAwesomeIcon,
        CartDetails
    },
    props: {
       
    }
})
export default class CartSummary extends Vue {
    faCartShopping=faCartShopping
    showCartDetails=false
    get cart() {
        return store.getters.cart;
    }
   

}


import store from '@/store';
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    props: {
       
    }
})
export default class CartDetails extends Vue {
    

 isCreatingOrder = false;
 createdOrder:any = null;
 currentCountDownSecond = 0;
  get isCartEmpty(){
    return this.cart.items.length == 0;
  }
    get cart() {
        return store.getters.cart;
    }

    get totalAmount(){
        return this.cart.items.reduce((acc:number, item:any) => acc + (item.product.price * item.quantity), 0);
    }
   checkout(){
    this.isCreatingOrder=true;
    store.dispatch('checkout').then((res:any)=>{
        console.log('orderresult',res);
        this.isCreatingOrder=false;
      this.createdOrder=res;
      this.$router.replace({name:'orderdetails', params:{id:res.id}})
      this.$emit('ordercreated',res)
      })
   }

}


import { Options, Vue } from 'vue-class-component';
import CartSummary from '@/components/Cart/CartSummary.vue'
@Options({
  components: {
    CartSummary
  },
})
export default class HomeView extends Vue {
 
}


import { Options, Vue } from 'vue-class-component';
import HomeView from '@/views/HomeView.vue'; // @ is an alias to /src
import store from './store';
@Options({
  components: {
    HomeView,
  },
})
export default class App extends Vue {

  created(){
    store.dispatch('loadRestorants');
  }
}
